import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import { Notifications } from "@/app/graphql-templates.mjs";
import NotificationsModel from "@/data-models/app-notifications.mjs";
import { queryGQL } from "@/util/gql-formatters.mjs";

export default async function fetchData() {
  await getData(
    queryGQL(appURLs.CMS, Notifications),
    NotificationsModel,
    ["volatile", "app", "notifications"],
    {
      shouldSkipDBRead: true,
      // This should be set to a time higher than average session time,
      // to avoid spamming backend.
      networkBackOffTime: 1 * 60 * 60 * 1000, // 1 hrs
    },
  );
}
